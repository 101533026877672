<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: createNewCustomer.vue
Description:This is form where new Customer details are filled and submitted by admin
-->
<template>
  <er-dialog
    :title="getDialogTitle"
    :visible="visible"
    :modal-append-to-body="false"
    :width="user_type === 'superAdmin'? '65%' : '35%'"
    :close-on-click-modal="false"
    @open="resetForm"
    @close="handleDialogClose"
    class="create-new-customer-sa"
    custom-class="create_new_customer_dialog"
  >
    <!-- <el-alert
      v-if="ehm__unhandledErrorMessage"
      :title="$t(ehm__unhandledErrorMessage)"
      type="error"
      @close="ehm__handleAlertClose"
    ></el-alert> -->
    <el-row>
      <el-col :span="user_type === 'superAdmin'? 12 : 24">
        <el-form
          :ref="formName"
          :model="user"
          size="mini"
          v-loading="loading"
          element-loading-background="white"
          :element-loading-text="$t('Comn_auth_loading')"
        >
          <!-- <el-form-item
            prop="user_type"
            class="name-element"
            label="Select Role"
          >
            <er-select
              v-model="user_type"
              :placeholder="'Select role'"
            >
              <template v-for="(value, index) in userTypeData">
                <el-option
                  :key="index"
                  :label="value.label"
                  :value="value.value"
                ></el-option>
              </template>
            </er-select>
          </el-form-item> -->
          <el-form-item
            prop="first_name"
            class="name-element"
            label="First Name"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.first_name)"
          >
            <el-input
              v-model="user.first_name"
              :placeholder="userFormLabels[0].placeholder"
            >
            </el-input>
          </el-form-item>

          <el-form-item
            prop="last_name"
            class="name-element"
            label="Last Name"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.last_name)"
          >
            <el-input
              v-model="user.last_name"
              :placeholder="userFormLabels[1].placeholder"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            prop="email"
            label="Email"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.email)"
          >
            <el-input
              v-model="user.email"
              :disabled="action === 'EDIT'"
              :placeholder="userFormLabels[2].placeholder"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            v-if="userType !== 'SKRETTING_TECHNICIAN'"
            prop="country"
            label="Country"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.country.name)"
          >
            <er-select
              v-model="user.country"
              value-key="code"
              autocomplete="new-country"
              filterable
              :placeholder="userFormLabels[3].placeholder"
            >
              <template slot="default">
                <el-option
                  v-for="(name, code) in countries"
                  :key="code"
                  :label="name"
                  :value="{ name, code }"
                ></el-option>
              </template>
            </er-select>
          </el-form-item>
          <el-form-item
            prop="phone"
            label="Phone Number"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.phone)"
          >
            <el-input
              class="country-select input-box"
              v-model="user.phone"
              :placeholder="userFormLabels[4].placeholder"
              autocomplete="off"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            v-if="action === 'ADD'"
            prop="password"
            label="Password"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.password)"
          >
            <el-input
              v-model="user.password"
              :placeholder="userFormLabels[5].placeholder"
              show-password
              autocomplete="new-password"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            v-if="action === 'ADD'"
            prop="confirm_password"
            label="Confirm Password"
            :error="
              aekmm__castErrorKeyToLang(ehm__errMessagesObject.confirm_password)
            "
          >
            <el-input
              v-model="user.confirm_password"
              :placeholder="userFormLabels[6].placeholder"
              @keyup.native.enter="submitForm"
              show-password
            >
            </el-input>
          </el-form-item>
          <el-form-item
            v-if="(userType === 'SALES_MANAGER' || userType === 'SALES_EXECUTIVE') && action === 'EDIT'"
            prop="status"
            label="Status"
          >
          <er-select
            v-model="user.status"
            placeholder="Select Status"
            @change="handleChangeInStatus"
          >
            <template v-for="(value, index) in this.statusData">
              <el-option
                :key="index"
                :label="value.label"
                :value="value.key"
              ></el-option>
            </template>
          </er-select>
          </el-form-item>
          <el-form-item
            v-if="(userType === 'SALES_MANAGER' || userType === 'SALES_EXECUTIVE')  && action !== 'EDIT'"
            label="Account Manager"
          >
          <el-checkbox v-model="isAccountManagerAccesschecked"></el-checkbox>
          </el-form-item>
        </el-form>
      </el-col>
      <!-- <el-col :span="12"  v-if="user_type === 'superAdmin'">
        <el-row class="sub-user-permission-selection" style="padding-left: 13px;">
          <layout-toolbar justify="start" class="sub-user-details-header">
            <span class="material-icons-outlined">verified_user</span>
            <p class="sub-user-details-header__title">
              Permissions
            </p>
          </layout-toolbar>
          <el-row style="margin: 10px 30px; font-size: 12px;">
            <template>
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">Select all</el-checkbox>
              <div style="margin: 5px 0;"></div>
              <template v-for="(tabData) of permissionToTabs">
                <el-row :key="tabData.label">
                  <el-col :span="8">
                    <span>{{tabData.label}}</span>
                  </el-col>
                  <el-col :span="16">
                    <el-checkbox v-for="permission of tabData.permissions" v-model="permission.checked" :label="permission.key" :key="tabData.label+'_'+permission.key">{{permission.key}}</el-checkbox>
                  </el-col>
                </el-row>
              </template>
            </template>
          </el-row>
        </el-row>
      </el-col> -->
    </el-row>
    <template slot="footer">
      <el-row type="flex" justify="end" align="middle">
        <er-button
          size="mini"
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :loading="loading"
          @click="submitForm"
        >
        </er-button>
        <er-button
          size="mini"
          btnType="cancel"
          :showLabel="true"
          @click="handleDialogClose"
        >
        </er-button>
      </el-row>
    </template>
  </er-dialog>
</template>
<script>
import User from "@/model/user.js";
import { mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import redirectsMixin from "@/mixins/redirectsMixin.js";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin.js";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";

export default {
  mixins: [
    errorHandlerMixin,
    redirectsMixin,
    authErrorKeyMapMixin,
    errorKeyMapMixin
  ],
  props: ["visible", "userType", "action", "model"],
  data: function() {
    return {
      user: new User(),
      countries: {},
      formName: "signUpForm",
      countryCode: "",
      loading: false,
      isAccountManagerAccesschecked: false,
      ehm__errMessagesObject: new User(),
      hasError: false,
      userTypeMap: {
        CUSTOMER: { label: "Customer" },
        DEALER: { label: "Dealer" },
        ACCOUNTMANAGER: { label: "Account Manager" },
        SKRETTING_TECHNICIAN: { label: "Skretting Technician" },
        SALES_MANAGER: { label: "Sales Manager" },
        SALES_EXECUTIVE: { label: "Sales Executive" }
      },
      ekmm__backndfieldToFieldNameMap: {
        email: "Comn_email",
        password: "Comn_password",
        first_name: "Usrs_first_name",
        last_name: "Usrs_last_name",
        confirm_password: "Comn_confirm_pwd"
      },

      ekmm__backndfieldToInvalidMap: {
        email: {
          EMAIL_ALREADY_EXISTS: "Usrs_email_already_exists",
          ACCOUNT_ALREADY_TAKEN: "Usrs_email_already_exists"
        },

        first_name: {
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Min 3 characters Required"
        },
        last_name: {
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Min 3 characters Required"
        },
        password: {
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Password Should contain min 6 characters"
        },
        confirm_password: {
          VALUES_SHOULD_MATCH: "Both password and confirm password must match"
        }
      },
      user_type: 'user',
      userTypeData: [{ label: "User", value: "user" },
                      { label: "Super Admin", value: "superAdmin" }],
      permissionToTabs: [{ label: "Customer", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" },
                          { label: "Devices", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" },
                          { label: "PondMother", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "sub" },
                          { label: "Gateway", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "sub" },
                          { label: "PondGuard", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "sub" },
                          { label: "ShrimpTalk", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "sub" },
                          { label: "FeedBlower", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "sub" },
                          { label: "ShrimpSnap", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "sub" },
                          { label: "Client", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" },
                          { label: "Dealer", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" },
                          { label: "Account Manager", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" },
                          { label: "Activity Log", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" },
                          { label: "Help", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" },
                          { label: "Inquiry Log", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" }
                          ],
       isIndeterminate: true,
       checkAll: false,
       checkedTabs: [],
       statusData: [
        { key: "ACTIVE", label: "Active" },
        { key: "INACTIVE", label: "Inactive" },
      ],
    };
  },
  computed: {
    getDialogTitle() {
      const userName = this.userTypeMap[this.userType].label;
      return this.action === "ADD" ? `Create ${userName}` : `Edit ${userName}`;
    },
    getTimeZones() {
      return this.$store.getters["user/getTimeZones"];
    },
    userFormLabels() {
      return [
        {
          label: this.$t("Usrs_first_name"),
          placeholder: this.$t("Usrs_enter_first_name")
        },
        {
          label: this.$t("Usrs_last_name"),
          placeholder: this.$t("Usrs_enter_last_name")
        },
        {
          label: this.$t("Comn_email_address"),
          placeholder: this.$t("Usrs_enter_email_addr")
        },
        {
          label: this.$t("Comn_country"),
          placeholder: this.$t("Usrs_enter_country")
        },
        {
          label: this.$t("Comn_mobile_num"),
          placeholder: this.$t("Usrs_enter_mobile_num")
        },
        {
          label: this.$t("Usrs_pwd"),
          placeholder: this.$t("Usrs_enter_pwd")
        },
        {
          label: this.$t("Comn_confirm_pwd"),
          placeholder: this.$t("Usrs_enter_confirm_pwd")
        }
      ];
    }
  },
  async mounted() {
    try {
      await this.fetchCountryList();
      this.countries = this.$store.getters["auth/getCountries"];
    } catch (err) {
      this.ehm__errorMessages(err, false);
    }
  },
  methods: {
    handleCheckAllChange(val) {
        this.checkAll = val;
        this.permissionToTabs = this.permissionToTabs.reduce((acc, cur) => {
          // const copyCur = cur;
          cur.permissions.map(permission => {
            permission.checked = val;
          });
          console.log("cur", cur);
          acc.push(cur);
          return acc;
        }, [])
        this.isIndeterminate = false;
      },
      handleChangeInStatus(data) {
        this.user.status = data
      },
    ...mapActions("auth", {
      signUp: "signUp",
      fetchCountryList: "fetchCountryList",
      AddDealer: "AddDealer",
      EditDealer: "EditDealer",
      AddAccountManager: "AddAccountManager",
      EditAccountManager: "EditAccountManager"
    }),
    ...mapActions("superadmin", {
      AddDealer: "AddDealer",
      AddAccountManager: "AddAccountManager",
      EditAccountManager: "EditAccountManager",
      EditDealer: "EditDealer",
      EditSkrettingTechnicians: "EditSkrettingTechnicians"
    }),

    ...mapActions("superadmin/salesManager", {
      AddSalesManager: "AddSalesManager",
      EditSalesManager: "EditSalesManager",
      AddSalesExecutive: "AddSalesExecutive",
      EditSalesExecutive: "EditSalesExecutive"
    }),
    ehm__error409Handler: function(err) {
      // let errorInnerHtml = ''
      if (err.response.data.errors != null) {
        const errorDetails = err.response.data.errors.details;
        errorDetails.forEach((el, index) => {
          // errorInnerHtml += '<li>' + el.message + '</li>'
          this.ehm__unhandledErrorMessage +=
            (index === 0 ? "" : ",") +
            el.message +
            (errorDetails.length - 1 === index ? "" : ",");
        });
      } else {
        err.response.data.key = "email";
        this.ehm__errMessagesObject.email = err.response.data;
        console.log(this.ehm__errMessagesObject.email);
      }
    },
    ehm__error403Handler: function(error) {
      this.ehm__errMessagesObject.email = {
        key: "email",
        ...error.response.data
      };
      this.ehm__errMessagesObject = Object.assign(
        {},
        this.ehm__errMessagesObject
      );
      console.log(this.ehm__errMessagesObject.email);
    },

    resetForm() {
      this.ehm__errMessagesObject = new User();
      this.user =
        this.action === "ADD" ? new User() : this.$lodash.cloneDeep(this.model);
    },
    handleDialogClose(message) {
      this.$emit("close", message);
      this.user_type = 'user';
      this.isAccountManagerAccesschecked = false;
    },
    async handleCountryChange(selectedCountry) {
      try {
        await this.$store.dispatch("user/fetchTimeZones", selectedCountry.code);
        this.timezones = this.getTimeZones;
        this.user.timezone = this.timezones[Object.keys(this.getTimeZones)[0]];
      } catch (err) {
        console.error(err);
      }
    },
    // ehm__error403Handler: function () {
    //   this.ehm__unhandledErrorMessage = "Usrs_email_already_in_use";
    // },
    async submitForm() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        if (this.user.country && this.user.country.name !== "") {
          await this.handleCountryChange(this.user.country);
        }
        this.user.first_name = this.trim_nameSpace(this.user.first_name);
        this.user.last_name = this.trim_nameSpace(this.user.last_name);
        this.user.email = this.trim_nameSpace(this.user.email);
        this.user.phone = this.trim_nameSpace(this.user.phone);
        if (this.userType === 'SALES_MANAGER' && this.action !== 'EDIT') {
          this.user.allowed_roles = this.isAccountManagerAccesschecked ? ["SALES_MANAGER", "ACCOUNT_MANAGER"] : ["SALES_MANAGER"];
        } else if (this.userType === 'SALES_EXECUTIVE' && this.action !== 'EDIT') {
          this.user.allowed_roles = this.isAccountManagerAccesschecked ? ["SALES_EXECUTIVE", "ACCOUNT_MANAGER"] : ["SALES_EXECUTIVE"];
        }
        this.ehm__errMessagesObject = new User();

        const actionToCall = {
          DEALER_ADD: this.AddDealer,
          DEALER_EDIT: this.EditDealer,
          CUSTOMER_ADD: this.signUp,
          ACCOUNTMANAGER_ADD: this.AddAccountManager,
          ACCOUNTMANAGER_EDIT: this.EditAccountManager,
          SALES_MANAGER_ADD: this.AddSalesManager,
          SALES_MANAGER_EDIT: this.EditSalesManager,
          SALES_EXECUTIVE_ADD: this.AddSalesExecutive,
          SALES_EXECUTIVE_EDIT: this.EditSalesExecutive,
          // SKRETTING_TECHNICIAN_ADD: this.addSkrettingTechnicians,
          SKRETTING_TECHNICIAN_EDIT: this.EditSkrettingTechnicians
        };

        const response = await actionToCall[`${this.userType}_${this.action}`](
          this.user
        );
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: response.message,
          duration: 10000,
          type: "success"
        });
        this.handleDialogClose("user_created");
      } catch (err) {
        this.ehm__errorMessages(err, false);
        // this.$notify({
        //   title: "Failed",
        //   message: "Failed to create a customer",
        //   duration: 10000,
        //   type: "error"
        // });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss">
.create-new-customer-sa {
  .el-form {
    display: grid;
  }
  .el-form-item.el-form-item--mini {
    display: grid;
    grid-template-columns: 1fr 2.4fr;
    grid-template-rows: 1fr;
    .el-form-item__label {
      grid-area: 1 / 1 / 2 / 2;
    }
    .el-form-item__content {
      grid-area: 1 / 2 / 2 / 3;
      .el-input-number {
        width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
    }
  }
}

.country-code-popper {
  width: 10% !important;
  margin-top: 5px;
  li {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    line-height: 1;
    .country {
      width: 30%;
      margin: 0px 10px;
      right: 0%;
      text-align: left;
    }
    .country-code {
      width: 70%;
      margin: 0px 10px;
      left: 0%;
    }
  }
  .popper__arrow {
    left: 12% !important;
  }
}
.create_new_customer_dialog {
  border-radius: 10px;
}
</style>
